"use client";

import { NextUIProvider } from "@nextui-org/react";
import { SessionProvider } from "next-auth/react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { usePathname } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode } from "react";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
}

function NextAuthProvider({ children }: { children: ReactNode }) {
  return <SessionProvider>{children}</SessionProvider>;
}

const lightThemePaths = [
  "/",
  "/pricing",
  "/about",
  "/login",
  "/contact",
  "/zh",
  "/en",
  "/ja",
  "/ja/pricing",
  "/en/pricing",
  "/zh/pricing",
  "/ja/about",
  "/en/about",
  "/zh/about",
  "/ja/login",
  "/en/login",
  "/zh/login",
  "/ja/contact",
  "/en/contact",
  "/zh/contact",
  "/blog",
  "/zh/blog",
  "/ja/blog",
];

const blogPaths = [
  "/career-advice",
  "/cover-letter",
  "/industries-news",
  "/interviewing",
  "/resume-guides",
  "/zh/career-advice",
  "/zh/cover-letter",
  "/zh/industries-news",
  "/zh/interviewing",
  "/zh/resume-guides",
  "/ja/career-advice",
  "/ja/cover-letter",
  "/ja/industries-news",
  "/ja/interviewing",
  "/ja/resume-guides",
  "/ja/qa",
];

export function Providers({ children }: { children: React.ReactNode }) {
  const pathName = usePathname() || "";

  const isForcedTheme =
    !pathName ||
    lightThemePaths.includes(pathName) ||
    blogPaths.find((res) => pathName.startsWith(res));

  return (
    <PostHogProvider client={posthog}>
      <NextAuthProvider>
        <NextUIProvider>
          <NextThemesProvider
            attribute="class"
            defaultTheme="light"
            forcedTheme={isForcedTheme ? "light" : undefined}
          >
            {children}
            <ProgressBar
              height="4px"
              color="#3fcf8e"
              options={{ showSpinner: false }}
              shallowRouting
            />
          </NextThemesProvider>
        </NextUIProvider>
      </NextAuthProvider>
    </PostHogProvider>
  );
}
