"use client";

import { Button } from "@nextui-org/react";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

const CookieNotice: React.FC = () => {
  const [value, setValue] = useState(true);
  const pathname = usePathname();

  useEffect(() => {
    const storageValue = window.localStorage.getItem("CookieNotice");

    if (storageValue) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, []);

  if (value || pathname.includes("resume")|| pathname.includes("coverletter")) {
    return null;
  }

  return (
    <section className="fixed bottom-16 right-12 mx-auto max-w-md rounded-2xl border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
      <p className="font-semibold text-gray-800 dark:text-white">
        🍪 Cookie Notice
      </p>

      <div className="mt-4 flex shrink-0 items-center justify-between gap-x-4">
        <p className="text-sm text-gray-600 dark:text-gray-300">
          We use cookies to ensure that we give you the best experience on our
          website.
        </p>
        <Button
          color="primary"
          onClick={() => {
            setValue(true);
            window.localStorage.setItem("CookieNotice", "true");
          }}
        >
          Accept
        </Button>
      </div>
    </section>
  );
};

export default CookieNotice;
